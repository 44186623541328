<template>
  <v-btn small depressed color="#b8e994" @click="openRenewMembershipDialog">
    <v-icon small left> mdi-account-check </v-icon>
    <span v-if="membershipType == 'family'"> Obnovi obiteljsko članstvo </span>
    <span v-else>Obnovi članstvo</span>
  </v-btn>
</template>

<script>
export default {
  props: ["member", "membershipType"],

  methods: {
    openRenewMembershipDialog() {
      this.$store.dispatch("openRenewMembershipDialog", {
        users: [this.member],
        type: this.membershipType,
      });
    },
  },
};
</script>
