<template>
  <v-dialog v-model="dialogShown" @input="hideDialog" width="600">
    <v-card>
      <v-card-title class="text-h6">
        Dodavanje novog člana u obitelj
      </v-card-title>

      <v-card-text>
        Pretraga člana:

        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="5">
                <v-text-field
                  v-model="firstName"
                  :rules="searchRules"
                  label="Ime"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="lastName"
                  :rules="searchRules"
                  label="Prezime"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn
                  small
                  depressed
                  color="primary"
                  class="mr-4 search-btn"
                  :disabled="loading"
                  @click="searchMember"
                >
                  Traži
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-simple-table v-if="searchResults.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Ime i prezime</th>
                <th class="text-left">Datum rođenja</th>
                <th class="text-left">Baza</th>
                <th class="text-left">Status</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in searchResults" :key="item.Id">
                <td>{{ item.FirstName }} {{ item.LastName }}</td>
                <td>{{ formatDate(item.Birthdate) }}</td>
                <td>
                  {{
                    item.Primary_Affiliate__r
                      ? item.Primary_Affiliate__r.Name
                      : ""
                  }}
                </td>
                <td :class="item.Active__c ? 'active' : 'inactive'">
                  {{ item.Active__c ? "Aktivan" : "Neaktivan" }}
                </td>
                <td>
                  <v-btn
                    small
                    depressed
                    color="success"
                    :disabled="loading"
                    @click="addMemberToHousehold(item.Id)"
                  >
                    Dodaj
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-col cols="12" class="text-center">
        <div v-if="loading">
          <v-progress-circular
            indeterminate
            right
            color="#1358a3"
          ></v-progress-circular>
        </div>

        <p v-show="error" class="error-text">
          {{ error }}
        </p>

        <p v-show="success" class="success-text">Član uspješno dodan!</p>
      </v-col>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="hideDialog()"> Odustani </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";

import mixin from "../../mixins/mixin";

export default {
  name: "AddHouseholdMemberDialog",

  props: ["householdId", "showDialog"],

  mixins: [mixin],

  data() {
    return {
      loading: false,
      error: false,
      success: false,
      dialogShown: false,
      valid: false,
      firstName: "",
      lastName: "",
      searchRules: [
        (v) => !!v || "Polje je obavezno",
        (v) => v.length >= 2 || "Mora sadržavati najmanje 2 slova",
      ],
      searchResults: [],
    };
  },

  watch: {
    showDialog(val) {
      this.dialogShown = !!val;
    },
  },

  methods: {
    hideDialog() {
      this.error = false;
      this.success = false;
      this.$emit("hide-dialog");
    },

    hideDialogAndResetForm() {
      this.hideDialog();
      this.$emit("reload-members");
      this.searchResults = [];
      this.$refs.form.reset();
    },

    async searchMember() {
      this.$refs.form.validate();

      if (!this.valid) {
        return;
      }

      this.error = false;
      this.success = false;
      this.loading = true;
      this.searchResults = [];

      try {
        this.searchResults = await apiRequest(
          "get",
          "members/search-for-household",
          {
            firstName: this.firstName,
            lastName: this.lastName,
          }
        );
      } catch (error) {
        if (error.response.data.message) {
          this.error = error.response.data.message;
        } else {
          this.error = error.message;
        }
      } finally {
        this.loading = false;
      }
    },

    async addMemberToHousehold(memberId) {
      this.error = false;
      this.success = false;
      this.loading = true;

      try {
        await apiRequest(
          "post",
          `households/${this.householdId}/add-member/${memberId}`
        );

        this.success = true;
        this.loading = false;

        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.hideDialogAndResetForm();
      } catch (error) {
        this.error = error.response.data.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-btn {
  top: 30%;
}

td.active {
  color: #27ae60;
}

td.inactive {
  color: #ce3137;
}
</style>
