<template>
  <div>
    <v-card class="pa-2" outlined tile>
      <div class="name">
        <h4>{{ member.FirstName }} {{ member.LastName }}</h4>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="member.Household_Role__c == 'Main'"
              v-bind="attrs"
              v-on="on"
              class="household-symbol"
            >
              mdi-account-star
            </v-icon>
          </template>
          <span>Nositelj obitelji</span>
        </v-tooltip>
      </div>

      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Status</td>
              <td>
                <span :class="`member-status ${member.Active__c}`">
                  {{ member.Active__c ? "Aktivan" : "Neaktivan" }}
                </span>
              </td>
            </tr>
            <tr>
              <td>Broj člana</td>
              <td>{{ member.Member_ID__c }}</td>
            </tr>
            <tr>
              <td>Baza članstva</td>
              <td>{{ member.Primary_Affiliate__r.Name }}</td>
            </tr>
            <tr>
              <td>Uloga</td>
              <td>
                {{ member.Household_Role__c == "Main" ? "Nositelj" : "Član" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="clearfix">
        <div class="actions">
          <v-btn
            small
            depressed
            color="#fad390"
            v-if="isDphMember(member)"
            @click="openMember(member.Id)"
          >
            <v-icon small left> mdi-pencil </v-icon>
            Uredi
          </v-btn>

          <v-btn
            small
            depressed
            color="#e74c3c"
            class="remove-btn"
            v-show="member.Household_Role__c == 'Member'"
            @click="$emit('show-dialog', member.Id)"
          >
            <v-icon small left> mdi-close-circle </v-icon>
            Ukloni
          </v-btn>

          <RenewMembershipButton
            v-if="!member.Active__c && member.Household_Role__c != 'Main'"
            :member="member"
            membershipType="single"
          />

          <RenewMembershipButton
            v-if="!member.Active__c && member.Household_Role__c == 'Main'"
            :member="member"
            membershipType="family"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import RenewMembershipButton from "../membership/RenewMembershipButton.vue";

export default {
  name: "HouseholdMember",

  components: { RenewMembershipButton },

  props: ["member"],

  data() {
    return {
      loading: false,
      error: false,
    };
  },

  methods: {
    isDphMember(member) {
      const currentUser = this.$store.state.account.user;

      if (currentUser.sf_data) {
        return member.Primary_Affiliate__c == currentUser.sf_data.Id;
      }
    },

    openMember(id) {
      this.$router.push({ name: "member", params: { id } }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  margin-bottom: 16px;

  h4 {
    display: inline;
  }
}

.actions {
  margin-top: 16px;
  text-align: right;

  button {
    display: inline;
    margin-left: 4px;
    margin-bottom: 4px;
  }

  .remove-btn {
    color: #fff;
  }
}
</style>
