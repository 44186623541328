<template>
  <v-col cols="12">
    <v-card class="pa-2 main-card" outlined>
      <div class="name">
        <h3>
          Član:
          <b>{{ existingMember.FirstName }} {{ existingMember.LastName }}</b>
        </h3>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="existingMember.Household_Role__c == 'Main'"
              v-bind="attrs"
              v-on="on"
              class="household-symbol"
            >
              mdi-account-star
            </v-icon>
          </template>
          <span>Nositelj obitelji</span>
        </v-tooltip>
      </div>
      <v-divider></v-divider>

      <v-container fluid class="member-data-top">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6" lg="5">
            <v-card class="pa-2" outlined tile>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td><b>Status člana</b></td>
                      <td :class="`member-status ${existingMember.Active__c}`">
                        {{ activeStatus }}
                      </td>
                    </tr>
                    <tr>
                      <td><b>Članski broj</b></td>
                      <td>{{ existingMember.Member_ID__c }}</td>
                    </tr>
                    <tr>
                      <td><b>Zadnja obnova članstva</b></td>
                      <td>
                        {{
                          formatDate(
                            existingMember.npo02__LastMembershipDate__c
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-card class="pa-2 buttons-holder" outlined tile>
              <div class="clearfix">
                <RenewMembershipButton
                  v-if="
                    !existingMember.Active__c &&
                    existingMember.Household_Role__c == 'Main'
                  "
                  :member="existingMember"
                  membershipType="family"
                />

                <div class="clearfix">
                  <RenewMembershipButton
                    v-if="!existingMember.Active__c"
                    :member="existingMember"
                    membershipType="single"
                  />
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>

      <v-container
        fluid
        class="member-data-form"
        v-if="Object.keys(member).length > 0"
      >
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6" lg="5" class="form-column">
            <v-card class="pa-2" outlined tile>
              <v-form ref="form" v-model="valid">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <h3 class="subsection">Osobni podaci</h3>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b>Ime *</b></td>
                        <td>
                          <v-text-field
                            required
                            v-model="member.FirstName"
                            :rules="nonEmptyRule"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Prezime *</b></td>
                        <td>
                          <v-text-field
                            required
                            v-model="member.LastName"
                            :rules="nonEmptyRule"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Datum rođenja *</b></td>
                        <td>
                          <v-menu
                            v-model="birthdateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="member.Birthdate"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                required
                                :rules="nonEmptyRule"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="member.Birthdate"
                              :max="maxCalendarDate"
                              min="1900-01-01"
                              locale="hr-hr"
                              @input="birthdateMenu = false"
                            ></v-date-picker>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Spol *</b></td>
                        <td>
                          <v-radio-group
                            v-model="member.Salutation"
                            row
                            required
                            :rules="nonEmptyRule"
                          >
                            <v-radio label="Muški" value="Male"></v-radio>
                            <v-radio label="Ženski" value="Female"></v-radio>
                          </v-radio-group>
                        </td>
                      </tr>
                      <tr>
                        <td><b>OIB</b></td>
                        <td>
                          <v-text-field required v-model="member.Tax_Number__c">
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Broj dokumenta</b></td>
                        <td>
                          <v-text-field required v-model="member.ID_Number__c">
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Baza članstva</b></td>
                        <td>
                          {{ member.Primary_Affiliate__r.Name }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3 class="subsection lower">Adresa</h3>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b>Država *</b></td>
                        <td>
                          <v-select
                            :items="countries"
                            item-text="name"
                            item-value="name"
                            v-model="member.MailingCountry"
                            required
                            :rules="nonEmptyRule"
                          ></v-select>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Ulica i kućni broj *</b></td>
                        <td>
                          <v-text-field
                            v-model="member.MailingStreet"
                            required
                            :rules="nonEmptyRule"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Poštanski broj *</b></td>
                        <td>
                          <v-text-field
                            v-model="member.MailingPostalCode"
                            required
                            :rules="nonEmptyRule"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Grad *</b></td>
                        <td>
                          <v-text-field
                            v-model="member.MailingCity"
                            required
                            :rules="nonEmptyRule"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-form>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="5" class="form-column">
            <v-card class="pa-2" outlined tile>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>
                        <h3 class="subsection">Kontakt</h3>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><b>Telefon</b></td>
                      <td>
                        <v-text-field
                          required
                          v-model="member.MobilePhone"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Email</b></td>
                      <td>
                        <v-text-field
                          required
                          v-model="member.npe01__HomeEmail__c"
                        ></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td><b>Ne kontaktiraj</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.npsp__Do_Not_Contact__c"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Ne šalji poštu</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.Mail_Opt_Out__c"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Ne šalji email</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.HasOptedOutOfEmail"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Ne šalji SMS</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.SMS_Opt_Out__c"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Ne zovi</b></td>
                      <td>
                        <v-checkbox v-model="member.DoNotCall"></v-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h3 class="subsection lower">Dodatno</h3>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><b>Bilo srce</b></td>
                      <td>
                        <v-checkbox v-model="member.Bilo_Srce__c"></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Preminuo</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.npsp__Deceased__c"
                        ></v-checkbox>
                      </td>
                    </tr>

                    <tr></tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

            <div class="save-actions">
              <v-btn
                v-if="!loading"
                @click="saveMember"
                depressed
                color="primary"
                :disabled="!valid"
              >
                Spremi promjene
              </v-btn>

              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="#1358a3"
                ></v-progress-circular>
              </div>

              <v-alert outlined text type="error" v-show="error">
                {{ error }}
              </v-alert>

              <v-alert outlined text type="success" v-show="success">
                Podaci uspješno spremljeni.
              </v-alert>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card class="pa-2 main-card" outlined>
      <h3>Obitelj</h3>
      <v-divider></v-divider>

      <HouseholdMembers
        v-if="member.npsp__HHId__c"
        :householdId="member.npsp__HHId__c"
      />
    </v-card>
  </v-col>
</template>

<script>
import apiRequest from "../helpers/apiRequest";
import { countries } from "../helpers/countries";
import HouseholdMembers from "../components/household/HouseholdMembers.vue";
import RenewMembershipButton from "../components/membership/RenewMembershipButton.vue";
import mixin from "../mixins/mixin";
import validateMemberForm from "../helpers/validateMemberForm";

export default {
  components: { HouseholdMembers, RenewMembershipButton },

  mixins: [mixin],

  data() {
    return {
      member: {},
      existingMember: {},
      loading: false,
      error: false,
      success: false,
      activePicker: null,
      birthdateMenu: false,
      countries: countries,
      valid: false,
      nonEmptyRule: [(v) => !!v || "Obvezno polje"],
    };
  },

  async created() {
    this.fetchMember();
    this.$store.dispatch("fetchDphs");
  },

  watch: {
    "$route.params.id"() {
      this.fetchMember();
    },

    renewMembershipCompleted(value) {
      if (value) {
        this.fetchMember();
      }
    },
  },

  computed: {
    activeStatus() {
      return this.existingMember.Active__c ? "Aktivan" : "Neaktivan";
    },

    maxCalendarDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },

    renewMembershipCompleted() {
      return this.$store.state.membershipRenewal.completed;
    },
  },

  methods: {
    async fetchMember() {
      this.resetFields();
      this.loading = true;

      try {
        const result = await apiRequest(
          "get",
          `members/${this.$route.params.id}`
        );
        this.member = Object.assign({}, result);
        this.existingMember = Object.assign({}, result);
      } catch (error) {
        this.error = error.response.data.message;
      } finally {
        this.loading = false;
      }
    },

    async saveMember(e) {
      e.preventDefault();
      this.resetFields();

      if (this.validateForm()) {
        this.loading = true;

        try {
          await apiRequest("patch", `members/${this.member.Id}`, this.member);
          this.fetchMember(); // Ponovno dohvaćamo podatke o članu jer je moguće da su se promijenili neki od podataka koji utječu na cijenu članarine (npr. datum rođenja)
          this.success = true;
        } catch (error) {
          this.error = error.response.data.message;
        } finally {
          this.loading = false;
        }
      }
    },

    validateForm() {
      this.$refs.form.validate();
      const { validated, error } = validateMemberForm(this.member);

      if (!validated || !this.valid) {
        this.error = error ? error : false;
        return false;
      }

      return true;
    },

    resetFields() {
      this.loading = false;
      this.error = false;
      this.success = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1264px) {
  .form-column:nth-child(1) {
    margin-right: 50px;
  }
}

.pa-2 {
  border: none;
}

.name {
  h3 {
    display: inline;
  }
}

.member-data-top {
  margin-top: 20px;
  margin-bottom: 20px;
}

.member-data-form {
  table tr:hover {
    background: none !important;
  }
  table tr td {
    min-width: 150px;
    border-bottom: 0px !important;
  }
}

.subsection {
  color: #1358a3;

  &.lower {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

.buttons-holder {
  button {
    margin-bottom: 10px;
  }
}

.save-actions {
  float: right;
  margin-top: 40px;

  button {
    margin-bottom: 20px;
  }
}
</style>
