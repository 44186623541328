<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="#1358a3"
            ></v-progress-circular>
          </div>

          <p v-show="error" class="error-text">{{ error }}</p>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="member-holder"
          v-for="member in members"
          :key="member.Id"
        >
          <HouseholdMember
            :member="member"
            @show-dialog="showRemoveMemberDialog"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
          class="member-holder new-household-member"
        >
          <v-card
            class="pa-2"
            outlined
            tile
            @click="toggleAddHouseholdMemberDialog(true)"
          >
            <v-card-text>
              <v-icon>mdi-account-plus-outline</v-icon> <br />
              Pridruži novog člana
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="memberRemoval.member"
      v-model="memberRemoval.dialogOpen"
      width="500"
      @input="hideRemoveMemberDialog"
    >
      <v-card id="removal-dialog">
        <v-card-title class="text-h6">
          Uklanjanje člana {{ memberRemoval.member.FirstName }}
          {{ memberRemoval.member.LastName }} iz obitelji
        </v-card-title>

        <v-card-text>
          Molimo potvrdite da želite ukloniti ovog člana iz obitelji.
        </v-card-text>

        <v-col cols="12" class="removal-loading">
          <div v-if="memberRemoval.loading">
            <v-progress-circular
              indeterminate
              right
              color="#1358a3"
            ></v-progress-circular>
          </div>

          <p v-show="memberRemoval.error" class="error-text">
            {{ memberRemoval.error }}
          </p>

          <p v-show="memberRemoval.success" class="success-text">
            Član uspješno uklonjen!
          </p>
        </v-col>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="hideRemoveMemberDialog()"> Odustani </v-btn>
          <v-btn
            text
            depressed
            color="#e74c3c"
            @click="removeMemberFromHousehold()"
            :disabled="memberRemoval.loading || memberRemoval.success"
          >
            Ukloni člana
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddHouseholdMemberDialog
      :householdId="householdId"
      :showDialog="addHouseholdMemberDialogShown"
      @hide-dialog="toggleAddHouseholdMemberDialog(false)"
      @reload-members="fetchHouseholdMembers"
    />
  </div>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";
import HouseholdMember from "./HouseholdMember.vue";
import AddHouseholdMemberDialog from "./AddHouseholdMemberDialog.vue";

export default {
  name: "HouseholdMembers",

  components: { HouseholdMember, AddHouseholdMemberDialog },

  props: ["householdId"],

  data() {
    return {
      members: [],
      loading: false,
      error: false,
      memberRemoval: {
        dialogOpen: false,
        member: null,
        loading: false,
        error: false,
        success: false,
      },
      addHouseholdMemberDialogShown: false,
    };
  },

  mounted() {
    this.fetchHouseholdMembers();
  },

  methods: {
    async fetchHouseholdMembers() {
      this.error = false;
      this.loading = true;

      try {
        this.members = await apiRequest(
          "get",
          `households/${this.householdId}/members`
        );
      } catch (error) {
        this.error = error.response.data.message;
      } finally {
        this.loading = false;
      }
    },

    showRemoveMemberDialog(memberId) {
      this.memberRemoval.member = this.members.find((m) => m.Id == memberId);
      this.memberRemoval.dialogOpen = true;
    },

    hideRemoveMemberDialog() {
      this.memberRemoval.dialogOpen = false;
      this.memberRemoval.member = null;
      this.memberRemoval.error = false;
      this.memberRemoval.success = false;
    },

    async removeMemberFromHousehold() {
      this.memberRemoval.error = false;
      this.memberRemoval.success = false;
      this.memberRemoval.loading = true;

      try {
        await apiRequest(
          "post",
          `households/${this.householdId}/remove-member/${this.memberRemoval.member.Id}`
        );

        this.memberRemoval.success = true;
        this.memberRemoval.loading = false;

        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.hideRemoveMemberDialog();
        this.fetchHouseholdMembers();
      } catch (error) {
        this.memberRemoval.error = error.response.data.message;
      } finally {
        this.memberRemoval.loading = false;
      }
    },

    toggleAddHouseholdMemberDialog(val) {
      this.addHouseholdMemberDialogShown = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.member-holder {
  margin-bottom: 10px;
  padding: 0 5px !important;
}

.new-household-member {
  text-align: center;

  .v-card {
    height: 100%;
    cursor: pointer;

    .v-icon {
      font-size: 3em;
      opacity: 0.7;
    }

    .v-card__text {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

#removal-dialog {
  .v-card__title {
    background-color: #1976d2;
    color: #fff;
  }

  .v-card__text {
    padding-top: 20px;
  }

  .removal-loading {
    text-align: right;
  }
}
</style>
